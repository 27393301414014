@import '../pnt/tools.scss';

$SOS-Call: #ff3333;
$SOS-Call-bg: #e52e2e;
//$leaflet-popup-bg: #242544;
$leaflet-popup-bg: white;
$index-bg-color: #e9e9ff;

///////////////////////////////////////
/////////// Smart Hospital ///////////
/////////////////////////////////////

.btn-white {
  background-color: white;
  border: 1px solid $gray-700;
}

.app-theme-pnt-dark {
  .custom-action-btn {
    color: white !important;
    background-color: transparent !important;
  }
  .bg-transparent-btn {
    background-color: transparent !important;
  }
}
.app-theme-pnt-light,
.app-theme-pnt-navy {
  .custom-action-btn {
    color: #6748e3 !important;
  }
}

.text-overflow {
  overflow-y: scroll;
}

.search-wrap {
  .pnt-btn.btn-icon {
    min-width: 5.8rem;
  }
}

.transition-card {
  transition: box-shadow 0.2s ease-in-out 0s;
  &:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.2s ease-in-out 0s;
  }
}

.custom-badge {
  border-radius: 50%;
  height: 25px;
  width: 25px;
  #root .app-container & {
    font-weight: bold;
  }
}

.table-btn {
  height: 27px;
}

.pnt-nameplate {
  .transform-scale-bigger {
    transform: scale(1.6) translateX(1.5px);
  }
}

.select-menu {
  .disabled-select-option {
    cursor: not-allowed !important;

    & > div,
    & > label {
      pointer-events: none;
    }
  }
  .disabled-tree-option {
    .rct-text {
      cursor: not-allowed !important;
      & > label {
        pointer-events: none;
      }
    }
  }

  .disabled-option {
    filter: opacity(0.5);
    .styled-option {
      cursor: not-allowed;
      & > label {
        pointer-events: none;
      }
    }
  }
}
.app-theme-pnt-dark .right-wrapper .right-header {
  background-color: #2c2c30;
}

.right-wrapper {
  width: 100%;
  height: 16rem;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  margin-right: 2rem;
  overflow: auto;
  .right-header {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    background-color: #f2f2f2;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 2rem 1rem;
    border-bottom: 1px solid #cfcfcf;
  }
  .transform-scale-medium {
    transform: scale(1.3);
  }
}

.block-ui.cursor-not-allowed .block-ui-container {
  cursor: not-allowed;
}

// 사이드바 하단
.app-main .pnt-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .pnt-sidebar-bottom {
    //border-top: 1px solid #e7e8f2;
    > .vertical-nav-menu > .metismenu-container > .metismenu-item > .metismenu-link {
      padding: 0 1.5rem;
      display: flex;
      //justify-content: end;
      > div {
        gap: 0.25rem;
      }
      .metismenu-icon {
        position: static;
      }
    }
  }
  .app-sidebar__inner {
    max-height: calc(100vh - 145px);
  }
}

.closed-sidebar > .app-main {
  .pnt-sidebar-bottom > .vertical-nav-menu .metismenu-container .metismenu-link {
    justify-content: center;
    > div {
      gap: 0 !important;
    }
    @media (max-width: 570px) {
      justify-content: end;
      > div {
        gap: 0.25rem !important;
      }
    }
  }
  .pnt-sidebar:hover {
    .pnt-sidebar-bottom > .vertical-nav-menu .metismenu-container .metismenu-link {
      justify-content: end;
      > div {
        gap: 0.25rem !important;
      }
    }
  }
}

.closed-sidebar .app-main .pnt-sidebar .metismenu-container .menu-border-box {
  border: none;
}
.closed-sidebar
  .app-main
  .app-sidebar.pnt-sidebar:hover
  .scrollbar-container
  .metismenu-container.visible
  .menu-border-box {
  border-bottom: 1px solid var(--blue);
}
.closed-sidebar .app-main .app-sidebar.pnt-sidebar:hover .pnt-sidebar-bottom .menu-border-box {
  border-top: 1px solid var(--font-black);
}
.closed-sidebar:not(.sidebar-mobile-open) .app-sidebar:hover .scrollbar-container {
  height: calc(100% - 140px);
}
// 사이드바 하단 종료 //

.cursor-default {
  cursor: default !important;
}

// SH v3 bookmark icon default color
.icon-filter-navy {
  filter: invert(44%) sepia(91%) saturate(5835%) hue-rotate(243deg) brightness(92%) contrast(92%) !important;
}

// SH v3 filter bar 여백
.filter_ver4 {
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 1rem !important;

  & .filter-box:last-child {
    margin-bottom: 0;
  }
}

.widget-util-wrapper {
  $iconSize: 38px;
  display: flex;

  & .util-group {
    display: flex;

    & > * {
      margin-left: 0.5rem;
      &:first-child {
        margin-left: 0;
      }
    }
    @media (max-width: $breakpoint-tablet) {
      display: none;
    }

    .util-icon {
      width: $iconSize;
      height: $iconSize;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .interval-time-icon {
        transform: scale(1.2) scaleX(-1) !important;
      }
      .interval-time-badge {
        position: absolute;
        bottom: 4px;
        right: 4px;
        width: 20px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: bolder;
        border-radius: 8px;
      }
    }

    & .dropdown {
      & .dropdown-menu {
        min-width: 7rem;
        border-color: $gray-400;

        &::before,
        &::after {
          display: none;
        }

        .dropdown-item {
          &:hover {
            background-color: $gray-300;
            &:first-child {
              border-radius: 4px 4px 0 0;
            }
            &:last-child {
              border-radius: 0 0 4px 4px;
            }
          }
        }
      }
    }
  }
}

/////////////////////////////////////
/////////// 메뉴 아이콘 설정 ///////////
// 표시아이콘 줄간격 강제 맞춤
.pnt-label--group .label-main.lh-38 {
  line-height: 38px;
  &.label-dot::before {
    top: 50%;
  }
}
// 선택된 아이콘 스타일
.icon-select-box {
  width: 100%;
  border: 1px solid #eceaf2;
  border-radius: 2px;
  padding: 1rem;
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(10%, 28px));
  gap: 20px;
  overflow: auto;
  & .menu-icon-wrapper {
    box-sizing: border-box;
    display: flex;
    padding: 4px;
    border: transparent 1px solid;

    &:hover {
      cursor: pointer;
      background-color: $gray-100;
      border-radius: 2px;
    }

    &.selected,
    &.selected:hover {
      border: $default-brand 1px solid;
      border-radius: 2px;
      filter: none;
      background-color: unset;
    }
  }
}
$menu-icon-filter-dark: invert(49%) sepia(82%) saturate(2656%) hue-rotate(1deg) brightness(105%) contrast(103%);
.icon-select-box {
  .menu-icon-wrapper {
    &.selected {
      border-color: $default-brand;
      .metismenu-icon {
        filter: none;
      }
    }
  }
}
.app-theme-pnt-dark,
.app-theme-pnt-light,
.app-theme-pnt-navy {
  .icon-select-box {
    .menu-icon-wrapper {
      &.selected {
        .metismenu-icon {
          filter: invert(20%) sepia(100%) saturate(3000%) hue-rotate(349deg) brightness(250%) contrast(100%);
        }
      }
    }
  }
}
.app-theme-pnt-dark,
.app-theme-pnt-navy {
  .icon-select-box {
    .menu-icon-wrapper {
      &.selected {
        border-color: #ff8800;
      }
    }
  }
}
.app-theme-pnt-light {
  .icon-select-box {
    .menu-icon-wrapper {
      &.selected {
        border-color: #ff7800;
      }
    }
  }
}

#root .app-container.app-theme-pnt-dark {
  @import '../pnt/theme/dark/dark';
  .metismenu-icon {
    filter: invert(100%);
  }
  .menu-icon-wrapper.selected .metismenu-icon {
    filter: $menu-icon-filter-dark;
  }
}
/////////// 메뉴 아이콘 설정 끝 ////////
/////////////////////////////////////

// 툴팁 오버 이벤트 방지
.fade.show {
  .tooltip.show {
    pointer-events: none;
  }
}

//////////////////////////////////////
/////////// Worker Safety ///////////
////////////////////////////////////

.btn-msg {
  background-color: #643eff;
  color: white;
  width: 100%;
}

.txt-medium {
  font-size: 1.15rem;
}

.react-checkbox-tree {
  label {
    display: flex;
  }

  .rct-title {
    word-break: break-word;
  }
}

// WorkSiteManagement
.workSite-management {
  & .card-body {
    height: calc(100vh - 270px);
  }

  & .card-footer {
    .footer-btn-container {
      width: 50%;

      & > Button {
        width: 100%;
      }
    }
  }

  .checkbox-container {
    background-color: $white;
    width: 8rem;
    z-index: 1000;
    border-radius: 3px;
    height: 38px;

    display: flex;
    justify-content: center;
    align-items: center;

    & p {
      color: rgba(0, 0, 0, 0.75);
    }
  }
}

// WorkSiteManagement - WorkSiteList
.txt-list {
  &:hover {
    cursor: pointer;
    .txt-index {
      background-color: $index-bg-color;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black !important;
      font-weight: 600;
    }
  }

  .txt-index {
    &.clicked {
      background-color: $index-bg-color;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black !important;
      font-weight: 600;
    }
  }
}

.custom-label-group {
  & .label-options {
    width: 100%;
    height: calc(100% - 120px);
    margin-top: 15px;
  }
}

.col-list {
  width: 160px;
  max-width: 100%;
}

// Alert Modal - SOS 구조요청
.sos-call {
  .icon-close {
    filter: invert(1);
  }

  & .card-header {
    background-color: $danger !important;
  }

  .sos-popup-container {
    .img-container {
      width: 40%;
      max-width: 6rem;
      overflow: hidden;
      border: 3px solid $danger;
      & img {
        width: 100%;
      }
    }

    .status-data {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .sos-mark-container {
      flex: 1;
      & .name {
        color: $f-b-3;
        font-weight: 700;
        font-size: 18px;
      }
    }
  }

  .sos-data-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    .bio-data {
      flex: 1;
    }

    .border-vert {
      &::before {
        content: '';
        display: block;
        width: 1px;
        height: 4.8rem;
        background-color: lightgrey;
        margin-top: 2rem;
      }
    }

    .safety-equipment {
      flex: 1;
    }
  }
}

// WorkSiteInformation - RealTimeOnSiteControl
.txt-overview-main {
  display: block;
  width: 100%;
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
  line-height: 1.2;
  margin-top: 0.6rem;
  letter-spacing: -1px;
  @media (max-width: 1440px) {
    font-size: 1.5rem;
  }
}

.txt-overview-sub {
  font-weight: 300;
  font-size: 0.8rem;
  color: gray;
  text-align: center;
  margin-top: 0.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 4rem;
  @media (max-width: 1440px) {
    font-size: 0.7rem;
    width: max-content;
  }
}

.txt-slash {
  display: block;
  height: 100%;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: $gray-400;
}

.overview_tab_list {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.4rem;
  border-bottom: 1px solid $gray-100;

  & .overview_tab_list__img {
    width: 30%;
    height: 80px;
    max-width: 5rem;
    border-radius: 5px;
    overflow: hidden;
    & img {
      width: 100%;
      height: 100%;
      padding-bottom: 7px;
    }
  }
  & .overview_tab_list__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    justify-content: flex-start;
    margin: 0 5px 0 0;
    padding: 3px 5px;

    &:hover,
    &.selected {
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 2px 2px 3px -2px;
    }

    @media (min-width: 1920px) {
      gap: 0;
      flex-direction: row;
      justify-content: space-between;
    }

    & .emergency-label {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 3px 8px;
      border-radius: 100px;
      transform: scale(0.85) translateX(-10%);
      width: 120px;
      //filter: saturate(2) ;
    }
    & .list-name {
      color: $f-b-3;
      font-weight: 700;
      font-size: 18px;
      display: flex;
      flex-direction: column;
      & .birthday {
        @media (min-width: $breakpoint-mobile) {
          flex-direction: row;
        }
      }
    }
    & .flx-col {
      width: 100%;
      text-align: left;
      @media (min-width: 1920px) {
        width: 60%;
        text-align: right;
        padding-right: 0.5rem;
        justify-content: flex-end;
      }
      & div {
        font-size: 0.8rem;
        line-height: 1.6;
        color: $gray-600;
        font-weight: 500;
        & .tab-label {
          display: inline-block;
          width: max-content;
          vertical-align: middle;

          font-size: 0.7rem;
          font-weight: 200;
          color: $gray-600;
        }
      }
    }
  }
}

.overlay-card {
  position: absolute;
  right: 0;
  top: 0;

  max-width: 100%;
  width: 400px;
  height: content-box;
  z-index: 11;
}

.collapsible-list {
  cursor: pointer;
  .label-main {
    flex: 100%;
  }
}

.status-by-floor {
  @media (max-width: 1400px) {
    display: none;
  }
}

.goseong-map {
  min-height: 32rem;
  .leaflet-pane {
    z-index: 10;
  }
  .leaflet-bottom .leaflet-right {
    z-index: 10;
  }
}

.map-status-box {
  text-align: center;
  text-shadow: none;
  .geofence-name {
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
    color: #00274a !important;
  }
  .map-status-label {
    background: rgba(0, 5, 105, 0.7);
    font-weight: 600;
    font-size: 1.1rem;
    padding: 0.1rem;
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    border-radius: 0.25rem;

    .status-label {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white !important;

      width: 1.5rem;
      height: 1.5rem;

      &.danger-label {
        border-radius: 0.25rem;
        border: 1px solid var(--gray-300, #a7aad1);
        background: rgba(167, 170, 209, 0.5);

        &.active {
          border: 1px solid var(--danger-base, #ff3358);
          background: rgba(255, 48, 48, 0.5);
        }
      }
    }

    .status-slash {
      color: var(--font-white-d-5, rgba(255, 255, 255, 0.35)) !important;
      margin: 0.25rem;
    }
  }
}

.place-center {
  place-items: center;
}

.tab-content {
  height: calc(100% - 38px);
}

.worker-list-height {
  height: calc(100% - 10px);
}

.floor-label {
  font-size: 1rem !important;
  &:before {
    width: 0.4em !important;
    height: 0.4em !important;
    top: 0.7em !important;
    left: 0.1em !important;
  }
}

.label-select-min-w {
  min-width: 8rem;
}

.select-label-group-min-w {
  min-width: 3rem !important;
}

// WorkInformation
.check-list-input {
  min-width: unset;
  width: unset;
}

//// 작업자 한명의 정보
// WorkSiteInformation - RealTimeOnSiteControl
// Monitoring - RealtimeLocationTracing
.worker-detail-container {
  display: flex;
  flex-direction: column;

  & .profile-container {
    display: flex;
    gap: 1rem;

    & .widget-worker-img {
      width: 43%;
      max-width: 10rem;
      border-radius: 5px;
      overflow: hidden;

      & img {
        width: 100%;
        height: 100%;
        padding-bottom: 7px;
      }
    }

    & .profile-content-container {
      display: flex;
      flex-direction: column;

      & .emergency-label {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 3px 8px;
        border-radius: 100px;
        transform: scale(0.85) translateX(-10%);
        //filter: saturate(2) ;
      }
    }
  }

  & .profile-name {
    display: flex;
    font-weight: 700;
    font-size: 16.5px;
    color: rgba(0, 0, 0, 0.75);

    div:first-child {
      max-width: 78%;
    }
  }

  & .profile-container {
    & .profile-label {
      color: rgba(0, 0, 0, 0.75);
    }
  }

  & .worker-detail-content {
    & .detail-card {
      box-shadow: 0 7px 16px rgba(24, 30, 111, 0.05);
      border-radius: 10px;
      margin: 1rem 0;

      & .status-data {
        &.on {
          color: $red;
        }

        font-size: 0.9rem;
        line-height: 1.6;
        font-weight: 700;
      }
    }
  }
}

// OperationManagement
.card-body-container {
  height: 100%;
  position: relative;

  .table-option {
    position: absolute;
    top: -8px;
    font-size: 0.8rem;
    font-weight: 700;
    background-color: #ebebeb;
    padding: 0.3rem 1.5rem 0.5rem;
  }
}

// OperationManagement - WorkplaceManagement
.error-txt {
  color: $default-danger !important;
  font-weight: 200;
  font-size: 13px;
}

// Card
//등록 수정 카드 (공통)
.detail-card-container {
  height: 100%;

  .txt-instruction {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
    color: $gray-600;

    &:first-of-type {
      margin-top: 1.5rem;
    }
    &:last-of-type {
      margin-bottom: 3rem;
    }
  }

  .icon-btn-container {
    width: 35%;

    & > Button {
      width: 100%;
    }

    &:last-of-type {
      width: 100%;
    }
  }

  .icon-img-container {
    height: 10rem;
    background: linear-gradient(0deg, #ffffff, #ffffff), #888888;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05), 3px 3px 15px rgba(107, 103, 100, 0.2);
    margin: 0 0 15px 0;
    padding: 0.25em 0.5em;

    display: flex;
    justify-content: center;
    align-items: center;

    &.form-must {
      border: 1px solid $brand;
      &::after {
        display: block;
        content: '*';
        position: absolute;
        top: -0.8rem;
        left: -0.3rem;
        line-height: auto;
        font-size: 1.1rem;
        font-weight: 400;
        color: #ff3333;
      }
    }

    .uploaded-icon {
      width: fit-content;
      max-width: 100%;
      height: 100%;
    }
  }
}

@mixin card-height($spacingHeight) {
  .card-height-#{$spacingHeight} {
    height: calc(100vh - #{$spacingHeight}rem);
    min-height: 20rem;
  }
}

@include card-height(18);
@include card-height(19);
@include card-height(21);
@include card-height(26);

.card-footer {
  .footer-btn-container {
    width: 20%;

    & > Button {
      width: 100%;
    }
  }

  .footer-btn-container:last-of-type {
    width: 80%;
  }
}

//////////////////////////////////////////////////
//작업관리 > 상단 검색필터 부분
// WorkManagement, SystemManagement
.search-container {
  //margin-bottom: 8px;
  .flx-end {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 1rem;

    .search-box {
      display: inline-flex;
      flex: 0 0 auto;
      align-items: center;
    }
  }

  .btn-container {
    margin-bottom: -1.3rem;
    margin-top: 2rem;
  }
}

// SystemManagement
.menu-permission-info.checkbox-container {
  width: 100%;
  height: 20rem;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  margin: 0 0.1em;
}

.message-setting.contents-container {
  height: 300px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  margin: 1.3rem 0.1em 0 0.1em;
  padding: 1rem 1rem;
}

// leaflet
.leaflet-container {
  .leaflet-popup {
    .leaflet-popup-content-wrapper,
    .leaflet-popup-tip {
      background-color: $leaflet-popup-bg !important;

      .label-main {
        min-width: 0;
      }
      .label-options {
        color: white;
      }
    }

    .leaflet-popup-content {
      width: max-content;
      .popup-with-delete {
        display: flex;
        width: max-content;
      }
    }

    a.leaflet-popup-close-button {
      //bottom: 0;
      //  left: 0;
      //  padding: 10px;
      //  width: 20rem;
      //  background-color: $gray-500;
      display: none;
    }

    .label-height-sm {
      min-height: 28px;

      .label-options {
        color: white !important;
      }
    }

    ::-webkit-scrollbar-thumb {
      border-color: lightgrey !important;
      background-color: #f6f5f8 !important;
    }
    ::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
  }
}

// OperationManagement
.terms-info.contents-container {
  height: 300px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  margin: 1.3rem 0.1em 0 0.1em;
  padding: 1rem 1rem;
}

// RealTimeSensorMonitoring, LocationPopup
.flx-row {
  &.row-space-around {
    justify-content: space-around;
  }

  &.row-space-between {
    justify-content: space-between;
  }
}

// WorkManagement, OperationManagement
// 메뉴별 목록 및 등록(수정) 레이아웃
.grid-layout-container {
  // 작업관리 > 작업장 관리
  &.work-site-management {
    grid-template-columns: 73% auto;

    @media (max-width: $breakpoint-tablet) {
      grid-template-columns: 100%;
      grid-template-rows: 30rem;
    }
  }

  display: grid;
  grid-template-columns: 65% auto;
  grid-gap: 10px;

  & .card-container {
    & .card {
      height: 100%;
    }
  }
  @media (min-width: $breakpoint-tablet) {
    height: calc(100vh - 135px);
  }

  @media (max-width: $breakpoint-tablet) {
    grid-template-columns: 100%;
  }
}

// CardModal
//팝업 - 중앙 모달
.pnt-popup__center {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;

  & .center__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 500px;
    @media (max-width: $breakpoint-mobile) {
      min-width: calc(100% - 2rem);
    }

    & .card {
      margin: 0;
      & .card-header {
        @media (max-width: $breakpoint-mobile) {
          flex-direction: row;
          justify-content: space-between;
          & .card-header__function {
            width: auto;
          }
        }
        & span {
          opacity: 1;
        }
      }
    }
  }
  &.active {
    display: block;
  }
}

.pnt-datepicker-container {
  width: 100%;
  &.form-must {
    &::after {
      display: block;
      content: '*';
      position: absolute;
      top: -0.8rem;
      left: -0.3rem;
      line-height: auto;
      font-size: 1.1rem;
      font-weight: 400;
      color: #ff3333;
    }
  }
}

// Action Modal
.input-textarea {
  padding-top: 1rem;
  textarea {
    &:hover,
    &:focus {
      box-shadow: none;
    }

    &.form-must {
      border: 1px solid $brand !important;
      border-radius: 5px;
    }
  }
}

// GeofenceLayer
.label-text-ellipsis {
  & .pnt-label--group {
    & .label-options {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      max-width: 160px;
    }
  }
}

.info-card {
  .info-card__title {
    padding: 0 1rem 0.5rem;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    width: 100%;
  }

  .info-card__body {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    border-radius: 0 30px 30px;
    box-shadow: 3px 5px 15px 0 rgba(24, 30, 111, 0.15);

    @media (max-width: $breakpoint-labtop) {
      .card-header__title {
        font-size: 20px;
      }
    }
    @media (max-width: $breakpoint-tablet) {
      .card-header__title {
        font-size: 24px;
      }
    }
  }
}

.border-top-right-20px {
  border-top-right-radius: 20px !important;
}

.search-table-card {
  height: calc(100vh - 10rem);
  min-height: 26rem;
}

.task-status-button {
  display: flex;
  align-items: center;
  @media (max-width: $breakpoint-tablet) {
    display: none;
  }
}

.task-status-text {
  display: none;
  align-items: center;
  @media (max-width: $breakpoint-tablet) {
    display: flex;
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.4rem;

  .page-header__title {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .title {
      font-size: 2rem;
      font-weight: 600;
    }

    button {
      font-size: 1rem;
      padding: 0 2rem;
      height: 39px !important;
      :hover {
        filter: none;
      }
    }
  }

  .page-header__button {
    display: flex;
    gap: 1rem;
  }

  .page-header__tab {
    font-size: 1.5rem;
    padding: 0 1.4rem;
    border-radius: 0;
    border-bottom: 4px solid transparent !important;
    height: 2.4rem;
    &.active {
      color: $default-brand !important;
      border-bottom: 4px solid $default-brand !important;
    }
  }

  @media (max-width: $breakpoint-labtop) {
    .page-header__title {
      .title {
        font-size: 1.5rem;
      }
      button {
        height: 1.6rem !important;
      }
    }
    .page-header__tab {
      font-size: 1rem;
      height: 1.9rem;
    }
  }
  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;

    .page-header__button {
      justify-content: center;
    }
    .page-header__title {
      width: 100%;
      justify-content: start;

      .title {
        width: fit-content;
        font-size: 1.3rem;
      }

      button {
        font-size: 12px;
        height: 1.4rem !important;
        padding: 0 1rem;
      }
    }
    .page-header__tab {
      font-size: 1.5rem;
      height: 2.4rem;
    }
  }
}

.work-information-details {
  display: flex;
  gap: 1.2rem;

  > div {
    flex: 1;
    button {
      min-width: unset;
      font-size: 0.8rem;
      padding: 0 0.5rem;
    }
  }

  .info-card {
    width: 100%;
  }

  @media (max-width: $breakpoint-labtop) {
    flex-direction: column;
  }
}

.check-list-header {
  width: calc(100% - 7rem);
}

.check-list-title {
  font-weight: bold;
  margin-top: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1.1rem;
  line-height: 1.1rem;
  max-width: 17rem;
  @media (max-width: $breakpoint-widemonitor) {
    max-width: 11rem;
  }

  @media (max-width: $breakpoint-labtop) {
    max-width: unset;
  }
}

.work-assessment-details {
  display: flex;
  padding: 0.1rem;
  justify-content: center;

  gap: 1.2rem;
  align-items: center;
  flex-flow: column wrap;

  .info-card {
    width: 100%;
    flex: 1;
  }

  @media (min-width: $breakpoint-labtop) {
    height: 92%;
    gap: 0.5rem;
    align-items: start;
    flex-flow: row wrap;
  }
}

.real-time-on-site-control {
  display: flex;
  width: 100%;
  height: calc(100% - 52px);
  gap: 0.2rem;
  padding-bottom: 0.2rem;

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
    height: unset;
    gap: 0.4rem;
  }
}

.on-site-dashboard {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  justify-content: center;

  .info-status-card {
    width: 24%;
    height: 50%;
  }

  &.specific-location {
    width: 36%;
    .info-status-card {
      min-width: 124px;
      min-height: 124px;
      width: 49%;
      height: 24%;
      .info-status-card-body {
        .info-status-card-img__desktop {
          width: 2.5rem;
          height: 2.5rem;
        }
        .info-status-card-text__desktop {
          font-size: 4rem;
        }
        .info-status-card-title {
          font-size: 1.5rem;
        }
      }
    }
  }

  @media (max-width: $breakpoint-labtop) {
    gap: 0.2rem;
    &.specific-location {
      .info-status-card {
        width: 48%;
        height: 24%;
        .info-status-card-body {
          padding: 0.5rem 1rem;
          .info-status-card-text__desktop {
            font-size: 2rem;
            font-weight: 600;
          }
          .info-status-card-title {
            font-size: 1.125rem;
          }
        }
      }
    }
  }

  @media (max-width: $breakpoint-tablet) {
    order: 2;
    gap: 0.2rem;
    .info-status-card {
      width: 48%;
      height: 24%;
    }
    &.specific-location {
      width: 100%;
    }
  }
}

.info-status-card {
  min-width: 212px;
  min-height: 256px;
  width: 100%;
  height: 100%;

  .info-status-card-body {
    padding: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .info-status-card-text__mobile {
      display: none;
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 0;
    }
    .info-status-card-title {
      font-size: 2.25rem;
      font-weight: 600;

      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .info-status-card-text__desktop {
      font-size: 6rem;
      font-weight: 800;
      margin-bottom: 0;
    }

    .info-status-card-img__desktop {
      width: 5rem;
      height: 5rem;
    }
    .info-status-card-img__mobile {
      display: none;
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  @media (max-width: $breakpoint-labtop) {
    .info-status-card-body {
      .info-status-card-text__desktop {
        font-size: 4rem;
      }
      .info-status-card-title {
        font-size: 1.5rem;
      }
    }
  }

  @media (max-width: $breakpoint-tablet) {
    min-height: 128px;
    min-width: 158px;
    width: 48%;
    height: unset;

    .info-status-card-body {
      .info-status-card-img__mobile,
      .info-status-card-text__mobile {
        display: block;
      }

      .info-status-card-img__desktop,
      .info-status-card-text__desktop {
        display: none;
      }

      .info-status-card-title {
        font-size: 1rem;
        font-weight: 700;
      }
    }

    // dashboard - 아이콘과 값이 row 정렬인 카드
    &.dashboard-card {
      min-height: 100px;
      .info-status-card-body {
        display: flex;
        flex-direction: row;
        align-items: start;
      }
    }
  }
}

.dashboard-blocks {
  height: 100%;
  .info-status-card {
    min-width: 128px;
    min-height: 100px;
    width: 100%;
    height: 100%;
    .info-status-card-body {
      @media (max-width: $breakpoint-labtop) {
        padding: 0.5rem 1rem;
      }
      @media (max-width: $breakpoint-tablet) {
        padding: 1rem;
      }
    }

    .info-status-card-img__desktop,
    .info-status-card-img__mobile {
      width: 2.5rem;
      height: 2.5rem;
    }

    .info-status-card-text__desktop {
      font-weight: 600;
      font-size: 4rem;
      @media (max-width: $breakpoint-labtop) {
        font-size: 2rem;
      }
    }

    .info-status-card-title {
      font-weight: 400;
      font-size: 1.5rem;

      @media (max-width: $breakpoint-labtop) {
        font-size: 1.2rem;
      }
      @media (max-width: $breakpoint-tablet) {
        font-weight: 700;
      }
    }
  }
}

.action-modal-label-container {
  display: flex;
  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }
}

.action-modal-message-settings {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1.25rem;
  justify-content: space-between;
  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }
}

.w-45 {
  width: 45%;
}

.action-history {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
  @media (max-width: $breakpoint-mobile) {
    display: none;
  }
}

.scale-07 {
  transform: scale(0.7);
}

.scale-08 {
  transform: scale(0.8);
}

/* 실시간 현장 관제 - Map Control*/
.leaflet-bar.leaflet-control,
.leaflet-control-zoom.leaflet-bar.leaflet-control {
  border: none !important;
  box-shadow: none !important;
  a {
    border: 1px solid $gray-300 !important;
  }
}

#select-container {
  .react-select__menu-portal {
    z-index: 1001;
  }
}

.control-container {
  .checkbox-container {
    background-color: $white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid $gray-300;
    border-radius: $form-radius;
    min-height: unset;
    padding: 0 0.6rem;
    font-weight: 600;
    height: 36px;
  }
  @media (max-width: $breakpoint-desktop) {
    &.map-control-outer {
      .react-select {
        min-width: 9rem;
        width: 100%;
      }
    }
  }
}

// UncontrolledTooltip 디자인 수정
.tooltip-inner {
  background-color: $white;
  color: #000000;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: $white;
}

.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: $white;
}

.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: $white;
}

.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: $white;
}

.map-control {
  @media (max-width: $breakpoint-labtop) {
    display: none !important;
  }
}

.map-control-outer {
  display: none;
  @media (max-width: $breakpoint-labtop) {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.map-outer {
  height: 100%;
  min-height: 20rem;
  @media (max-width: $breakpoint-labtop) {
    height: calc(100% - 88px);
  }
  @media (max-width: $breakpoint-tablet) {
    height: 76%;
  }
}

.map-bottom {
  width: fit-content;
  display: none;

  @media (max-width: $breakpoint-labtop) {
    display: block;
    margin: 0.5rem 0;
  }
}

.cell-highlight {
  padding: 5px 8px;
  color: #e0e0ff !important;
  height: 100%;
  background: linear-gradient(0deg, #5966f9, #5966f9), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
}

.cell-normal {
  padding: 5px 8px;
  height: 100%;
}

.label-card {
  width: 47%;

  .label-card__value {
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 0;
  }
  .label-card__title {
    font-weight: 400;
    font-size: 1.125rem;
  }

  @media (max-width: $breakpoint-labtop) {
    .label-card__value {
      font-size: 1.5rem;
    }
    .label-card__title {
      font-size: 0.8rem;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    width: 48%;

    .label-card__value {
      font-size: 2rem;
    }
    .label-card__title {
      font-size: 1.2rem;
    }
  }
}

.label-card-border {
  @media (max-width: $breakpoint-desktop) {
    border-radius: 0 10px 10px !important;
  }
}

.dashboard-layout {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 0.4rem;

  .dashboard-layout__block {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 1rem;
  }

  .dashboard-blocks {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    .dashboard-blocks__title {
      font-weight: 600;
      font-size: 1.5rem;
      text-align: center;
      width: 100%;
    }

    .dashboard-blocks__body {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      height: 100%;
      width: 100%;
    }
    .dashboard-block {
      display: flex;
      flex-wrap: wrap;
      gap: 0.4rem;
      width: 100%;
      height: 100%;
      justify-content: space-around;
    }
    .dashboard-alarm-table {
      width: 100%;
      height: 18rem;
    }
  }

  @media (max-width: $breakpoint-labtop) {
    .dashboard-blocks {
      .dashboard-blocks__title {
        font-size: 1rem;
      }
      .dashboard-alarm-table {
        height: 12rem;
      }
    }
    .dashboard-layout__block {
      gap: 0.5rem;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
    width: 100%;

    .dashboard-layout__block {
      gap: 1.5rem;
    }

    .dashboard-blocks {
      .dashboard-blocks__title {
        font-size: 1.5rem;
      }
      .dashboard-blocks__body {
        flex-direction: column;
        &.worker-status {
          gap: 0;
          .dashboard-block:first-child {
            margin: 0.5rem 0;
          }
          .dashboard-block .info-card .info-card__body {
            padding: 1rem;
          }
        }

        &.work-status {
          flex-direction: row;
          .label-card {
            width: 100%;
          }
        }
      }

      .dashboard-img {
        display: none;
      }
      .info-status-card {
        order: -1;
      }
    }
  }
}

.weather-container {
  height: 100%;
  flex-direction: column;
  padding: 0.5rem;
  background: linear-gradient(95deg, #000569 0%, #7982ab 100%),
    linear-gradient(180deg, rgba(26, 61, 255, 0.6) 0%, rgba(26, 61, 255, 0.24) 100%), lightgray 50% / cover no-repeat;

  @media (max-width: $breakpoint-labtop) {
    border-radius: 0 10px 10px !important;
  }
  @media (max-width: $breakpoint-tablet) {
    border-radius: 0 30px 30px !important;
    padding: 0.3rem 0.1rem;
  }

  .weather-header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    gap: 1.6rem;
    width: 100%;

    .weather-header__title {
      display: flex;
      align-items: center;
      color: white !important;
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 0;
    }
    .weather-header__unit {
      color: white !important;
      font-size: 2rem;
      font-weight: 400;
    }
    .weather-header__content {
      color: white !important;
      font-size: 1.25rem;
      font-weight: 500;
    }

    @media (max-width: $breakpoint-labtop) {
      .weather-header__title,
      .weather-header__unit {
        font-size: 1.8rem;
      }
      .weather-header__content {
        font-size: 0.8rem;
      }
    }

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      .weather-header__title {
        font-size: 2.5rem;
      }
      .weather-header__unit {
        font-size: 2rem;
      }
      .weather-header__content {
        font-size: 1.14rem;
      }
    }
  }

  .weather-blocks {
    display: flex;
    justify-content: center;
    flex: 1;

    .weather-block {
      width: 100%;
      min-width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 1rem;
      border-right: 1px solid white;

      img {
        width: 1.5rem;
        height: 1.5rem;
      }
      .weather-block__value {
        font-size: 1.25rem;
      }
      .weather-block__label {
        font-size: 0.875rem;
      }
    }

    :last-child {
      border-right: none;
    }

    @media (max-width: $breakpoint-labtop) {
      .weather-block {
        img {
          width: 1.3rem;
          height: 1.3rem;
        }
        .weather-block__value {
          font-size: 0.9rem;
        }
        .weather-block__label {
          font-size: 0.63rem;
        }
      }
    }
    @media (max-width: $breakpoint-tablet) {
      .weather-block {
        img {
          width: 1.5rem;
          height: 1.5rem;
        }
        .weather-block__value {
          font-size: 1.25rem;
        }
        .weather-block__label {
          font-size: 0.875rem;
        }
      }
    }
  }
}

.weekly-weather {
  display: flex;
  width: 100%;
  gap: 0.1rem;
  margin-top: 1rem;

  .daily-weather {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
    padding: 0.5rem;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    .daily-weather__content {
      font-size: 0.875rem;
      font-weight: 400;
      color: white !important;
    }
    img {
      width: 0.8rem;
      height: 0.8rem;
    }
  }

  :first-child {
    border-radius: 4px 4px 4px 20px;
  }
  :last-child {
    border-radius: 4px 4px 20px;
  }

  @media (max-width: $breakpoint-labtop) {
    margin-top: 0.2rem;
    .daily-weather {
      gap: 0;
      padding: 0.2rem;
      border-radius: 4px;

      .daily-weather__content {
        font-size: 0.63rem;
      }
      img {
        width: 0.6rem;
        height: 0.6rem;
      }
    }
  }

  @media (max-width: $breakpoint-tablet) {
    .daily-weather {
      gap: 0.1rem;
      .daily-weather__content {
        font-size: 0.875rem;
      }
      img {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
    :first-child {
      border-radius: 4px 4px 4px 20px;
    }
    :last-child {
      border-radius: 4px 4px 20px;
    }
  }
}

.IoT-sensor-card {
  width: 100%;
  height: 100%;
  .info-status-card {
    min-width: unset;
  }
  .IoT-sensor-card__body {
    display: flex;
    flex-direction: row;
    padding: 0;
    height: 100%;

    .gas-sensor {
      display: flex;
      flex-direction: column;
      padding: 1rem 0.5rem;
      height: 100%;
      gap: 0.2rem;

      .gas-sensor__label {
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 1px solid;
        border-radius: 1.4rem;
        flex: 1;
        padding: 0.2rem 0.6rem;
      }
    }
  }
  @media (max-width: $breakpoint-labtop) {
    .IoT-sensor-card__body .gas-sensor {
      padding: 0.5rem;
      .gas-sensor__label {
        padding: 0.1rem 0.3rem;
        font-size: 0.6rem;
      }
    }
  }

  @media (max-width: $breakpoint-tablet) {
    height: unset;
    .IoT-sensor-card__body {
      width: 100%;
      height: 100%;
      flex-direction: column;

      .gas-sensor {
        flex-direction: row;
        height: unset;
        width: 100%;
        padding: 1rem 0.5rem 0.5rem;
        .gas-sensor__label {
          font-size: 1rem;
          padding: 0.2rem;
        }
      }
    }
  }
}

.top-button-table-container {
  height: calc(100% - 1rem);
  margin-bottom: 10px;
}

.btn-highlight {
  background: linear-gradient(90deg, #5562f5 0.42%, #4bc9ff 100.42%);
  color: white !important;
}

.bg-brand-linear {
  background: linear-gradient(125deg, #5562f5 0.09%, #4bc9ff 99.95%);
}

.card-bg-none {
  background: none !important;
  box-shadow: none !important;
}

.search-wrap--group {
  flex-direction: row !important;
  .pnt-select--group {
    //width: 6rem;
    width: 7rem;
    .react-select__control {
      .select__btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  .input-wrap .pnt-input--group input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #a7aad1 !important;
  }
}

.attachment-body {
  max-height: 31rem;
  width: 100%;
  overflow-y: auto;
}

.width-without-scroll {
  word-break: break-word;
  width: calc(100% - 0.5rem);
}

.attached-file {
  width: calc(100% - 1.5rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attached-file-container {
  max-height: 29rem;
  overflow-y: auto;
}

.user-profile-container {
  display: flex;
  @media (max-width: $breakpoint-mobile) {
    flex-direction: column;
    gap: 1rem;
    > div:first-child {
      align-items: center;
    }
  }
}

// AppHeader - Timeline animation
@media only screen and (max-width: $breakpoint-labtop) {
  /* .vertical-timeline--animate */
  .vertical-timeline-element-content.bounce-in {
    animation: none !important;
  }
}

// Location Popup 크기
.alert-location-popup {
  @media (max-width: $breakpoint-tablet) {
    min-width: 90%;
  }
}

// TimePicker 클릭시 넓이가 줄어들었다 늘어났다 하는 현상 수정
.react-datepicker__tab-loop {
  margin-left: 0 !important;
}

// EvacuationDot을 위한 class
#root .app-container .app-header .app-header__content .app-header-right .evacuation-dot {
  background-color: transparent !important;
}

.main-layout:has(.fullscreen) {
  margin: 0;
}

.main-layout {
  margin: 0.5rem;
  min-height: 30rem;
  overflow-x: auto;
}

.default-inner-height {
  height: calc(100vh - 2rem);
}

.main-card-height {
  height: calc(100% - 0.5rem);
  min-height: 25rem;
}

.font-size-xxlg {
  font-size: 1.7rem !important;
  @media (min-height: $breakpoint-detachable-height) {
    font-size: 1.3rem !important;
  }

  @media (max-width: $breakpoint-desktop) {
    font-size: 1.2rem !important;
  }
}

.font-size-xxxlg {
  font-size: 2rem !important;
  @media (min-height: $breakpoint-detachable-height) {
    font-size: 2.5rem !important;
  }

  @media (max-width: $breakpoint-desktop) {
    font-size: 1.4rem !important;
  }
}

.people-count {
  line-height: 3.2rem;
  font-size: 4rem;
  @media (min-height: $breakpoint-detachable-height) {
    line-height: 3.8rem;
    font-size: 5.5rem;
  }

  @media (max-width: $breakpoint-desktop) {
    line-height: 2.7rem;
    font-size: 3.5rem;
  }
}

.evacuation-icon-size {
  height: 1.8rem;
  @media (min-height: $breakpoint-detachable-height) {
    height: 2.5rem;
  }

  @media (max-width: $breakpoint-desktop) {
    height: 1.4rem;
  }
}

.location-title {
  font-size: 0.9rem;
  @media (min-height: $breakpoint-detachable-height) {
    height: 1.1rem;
  }
}

.filter-search-table {
  height: calc(100% - 6rem);
}

.search-table {
  height: calc(100% - 2.4rem);
}

.multi-search-table {
  height: calc(100% - 7.2rem);
}

.min-w-5 {
  min-width: 5rem !important;
}

// 실시간 위치 현황
.real-time-location-status {
  display: grid;
  grid-template-columns: 15rem 1fr 15rem;
  grid-auto-rows: minmax(auto, 100%);
  width: 100%;
  height: 100%;
  gap: 0.5rem;

  .marker-label {
    background-color: transparent !important;
    color: black;
    font-size: 1rem;
    font-weight: 700;
    text-shadow: 1px 1px 0 white;
  }
}

@keyframes red-pulse-animation {
  0% {
    box-shadow: 0 0 0 0 #ff3358;
  }
  70% {
    box-shadow: 0 0 0 20px rgba(255, 51, 88, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 51, 88, 0.5);
  }
}

@keyframes non-returnable-pulse-animation {
  0% {
    box-shadow: 0 0 0 0 #04277d;
  }
  70% {
    box-shadow: 0 0 0 20px rgba(4, 39, 125, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(4, 39, 125, 0.5);
  }
}

@keyframes no-movement-pulse-animation {
  0% {
    box-shadow: 0 0 0 0 #ff8800;
  }
  70% {
    box-shadow: 0 0 0 20px rgba(255, 136, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 136, 0, 0.5);
  }
}

.red-pulse {
  border-radius: 50%;
  animation: red-pulse-animation 3s infinite;
}

.non-returnable-pulse {
  border-radius: 50%;
  animation: non-returnable-pulse-animation 3s infinite;
}

.no-movement-pulse {
  border-radius: 50%;
  animation: no-movement-pulse-animation 3s infinite;
}

.message-delivery-select {
  min-width: 11.6rem;
}

.border-radius-125 {
  border-radius: 0.125rem;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.reactive-font-normal {
  @media (max-width: $breakpoint-desktop) {
    font-size: 0.7rem;
  }
}

.personnel-status-card-body {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (max-width: $breakpoint-desktop) {
    gap: 0.5rem;
  }
}

.evacuation-situations-title {
  margin-bottom: 0.25rem;
  font-weight: bold;
  font-size: 1.3rem;

  @media (max-width: $breakpoint-desktop) {
    margin-bottom: 0;
    font-size: 1.1rem;
  }
}

.login-logo {
  display: inline-block;
  width: fit-content;
  padding: 0.6rem 0.8rem 0.7rem 0;
  margin-bottom: 1rem;
}

.logo-src {
  width: 9.375rem !important;
  height: 2rem !important;
  margin-top: 0 !important;
}

.header__logo {
  width: 100%;
  height: 100%;
  display: block;
}

.form-height-big {
  height: $form-height-big !important;
}

.login-form {
  font-size: 0.9rem;
  padding: 0 1.8rem 0 1rem !important;
}

// 사이드바 상위 메뉴
.metismenu > .metismenu-container > .metismenu-item > .metismenu-link > span {
  font-weight: bold !important;
}

// 사이드바 하위 메뉴
.vertical-nav-menu > .metismenu-container > .metismenu-item > ul.metismenu-container.visible > li > a.metismenu-link {
  margin-left: 0.15rem;
}

.bg-marker {
  background-color: #3076c1;
}

.realtime-card {
  height: calc(100% - 19rem);
}

.bg-no_movement {
  background-color: #ff8800;
}

.bg-non_returnable_export {
  background-color: #04277d;
}

.list-border-bottom {
  border-bottom: 1px solid #e7e7eb;
}

.filter-no_movement {
  filter: invert(55%) sepia(23%) saturate(4332%) hue-rotate(1deg) brightness(104%) contrast(104%);
}

.filter-non_returnable_export {
  filter: invert(10%) sepia(81%) saturate(3569%) hue-rotate(224deg) brightness(96%) contrast(104%);
}

.bg-alert {
  background-color: #fff4f6;
}

.list-hover {
  cursor: pointer;
  &:hover {
    background-color: #f0eff5 !important;
  }
}

.spin-button-none {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.label-area-min-height {
  min-height: 3.75rem;
}

.locker-shape {
  min-width: 2.5rem;
  min-height: 2.5rem;
}

.border-locker {
  border: 1px solid #e7e7eb !important;
}

.bg-personnel-alert {
  background-color: #fff4f6;
}

.realtime-location-desc {
  width: 17rem;
}

.flx-2 {
  flex: 2;
}

.flx-3 {
  flex: 3;
}

.simple-marker {
  width: 15px;
  height: 15px;
  background-color: #3076c1;
  border-radius: 50%;
}

.labeled-marker {
  width: 15px;
  height: 15px;
  background-color: #3076c1;
  border-radius: 50%;

  .marker-label {
    display: flex;
    background-color: transparent !important;
    color: black;
    font-weight: 700;
    text-shadow: 1px 1px 0 white;
    position: absolute;
    white-space: nowrap;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.leaflet-control-rotate {
  display: none !important;
}

.bg-current_location {
  background-color: #6f6e7a;
}

.cell-status {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cell-danger {
  color: #ff3358 !important;
  background-color: #ffebee;
}

.cell-no_movement {
  color: #94771b !important;
  background-color: #fff5d5;
}

.category-circle {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 1rem;
}

.w-fit-content {
  width: fit-content;
}

.text-outline {
  text-shadow: 1px 1px 0 white, -1px -1px 0 white, -1px 1px 0 white, 1px -1px 0 white;
}

.leaflet-popup-content {
  width: unset !important;
}

.closed-sidebar .sidebar-logo {
  display: none;
}
