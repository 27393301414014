//////////////////////////////
/* Css link*/
@import 'font';
@import 'tools';
//////////////////////////////

///////////////////////////////////
//
// ※확인해주세요
// Asset-Public 만을 위한 css 입니다.
//
///////////////////////////////////

::selection {
  background-color: $default-brand;
  color: $white;
}

.box {
  width: 100%;
  height: 3.4rem;
  position: relative;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  & span {
    position: absolute;
    width: 100%;
    height: 1rem;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: white !important;
    padding: 0 5px;
    @include p5-n();
    overflow: hidden;
  }
}

.ap-label {
  display: inline-block;
  padding: 0.4rem 1rem;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  @include p3-b();
  margin-bottom: 1rem;

  &::before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 100px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    background-color: black;
  }
}

#root .app-container.app-theme-pnt-dark {
  & .ap-label {
    background-color: rgba(255, 255, 255, 0.2);
    color: $f-b-3;
  }
  & .bg-light,
  .bg-l-1,
  .bg-l-2,
  .bg-l-3,
  .bg-warning {
    color: $f-b-4;
    & * {
      color: $f-b-4;
    }
  }
}

// 모바일 페이지에서의 타이틀
.mobile-page-title {
  display: none;
  @media (max-width: $breakpoint-mobile) {
    display: flex;
    padding: 1.5rem;
    align-items: center;
    justify-content: center;
    @include p1-b(1.2);
    & .menu-sub {
      color: $f-b-6;
    }
    & .icon-next {
      opacity: 0.4;
    }
    & .menu-main {
      margin-left: 0.3rem;
    }
  }
}

//서치
.search-open {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.search-close {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

//대시보드내 위젯 검색 버튼
.widget-search {
  & .filter_ver4 {
    &.absolute-filter {
      position: absolute;
      width: 100%;
      z-index: 1001;
      padding: 10px;

      & .search-box {
        margin-top: 0;

        & .filter-list {
          flex-wrap: wrap;
        }
      }

      & .filter-box {
        margin-bottom: 0;
        @media (max-width: $breakpoint-mobile) {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}

.widget-edit-btn {
  display: flex;
  @media (max-width: $breakpoint-mobile) {
    position: absolute;
    top: 15px;
    right: 0;
  }
}

.btn-mobile-search {
  @media (max-width: $breakpoint-tablet) {
    position: absolute;
    top: 5px;
    right: 15px;
    &.folded {
      transition: all 0.5s;
      opacity: 0;
      visibility: hidden;
    }
  }
}

//맵
.map-text {
  &.checkbox-container {
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $gray-700;
    padding: 0 3px;
    & p {
      color: rgba(0, 0, 0, 0.75) !important;
    }
  }
  text-align: center !important;
  color: rgba(0, 0, 0, 0.75) !important;
}
.leaflet-container {
  & .popup-open {
    position: absolute;
    z-index: 1000;
    background-color: $white;
    max-width: 100%;
    &.floorDetail {
      max-width: 100%;
      width: max-content;
      max-height: 370px;
      color: $black;
    }
    &.filtering {
      max-width: 100%;
      width: max-content;
      height: 325px;
    }
    .header {
      width: 25%;
      text-align: center;
    }
  }
  & .custom-control {
    display: flex;
  }
  & .toast-body {
    height: 280px;
  }
}
.leaflet-control {
  box-shadow: none !important;
  border-radius: $form-radius;
  a {
    border-radius: $form-radius !important;
  }
}
.leaflet-control-layers-overlays span {
  color: rgba(0, 0, 0, 0.75) !important;
}
.custom-tooltip {
  margin: 0;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
}
.custom-tooltip span {
  text-align: center !important;
  color: rgba(0, 0, 0, 0.75) !important;
}
//.leaflet-tile-container img {
//  width: 256.8px !important;
//  height: 256.8px !important;
//}
.custom-widget-search {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

//필터 ?
.filter__options {
  & .select-container {
    width: 100%;
  }
}

//카드 ?
.card-custom {
  z-index: 0;
  width: 20rem;
  margin: auto auto 3rem;
}
.card-body {
  & .scrollbar-container {
    width: 100%;
    overflow: auto;
    height: 100%;
  }
}

//분류 안됌
.monitoring-img {
  position: relative;
  background-color: white;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: $card-shadow;
  text-align: center;
  padding: 0.3rem;
}
.locationLog-container {
  & .popup-open {
    position: absolute;
    z-index: 1000;
    background-color: $white;
    max-width: 50vw;
    width: 50vw;
    height: 70%;
    padding-bottom: 2rem;
  }
  & .toast-body {
    height: 100%;
  }
}

.block-overlay-dark {
  height: 100%;
}
.count-container {
  @media (max-width: $breakpoint-tablet) {
    padding-top: 1rem;
  }
}
.btn-yesterday {
  color: #5966f9 !important;
  border: 1.5px solid #5966f9 !important;
  //@media (max-width: $breakpoint-tablet) {
  //    width: 100%;
  //}
}

//툴스
.flx-row {
  &.row-space-around {
    justify-content: space-around;
  }
}

.location-log-list {
  &.filter_ver4 {
    padding-bottom: 0;
  }
}

.metismenu-icon {
  width: 20px !important;
  height: 20px !important;
  margin-top: 0 !important;
  top: unset !important;
  left: 12px !important;
  opacity: 0.6 !important;
  &:hover {
    opacity: 1;
  }
}

.nav-child-active {
  .metismenu-icon {
    font-weight: bolder;
    opacity: unset !important;
  }
}

//커스텀 알람
.alarm-list {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.alarm-tracing-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
}

.alert-location-popup {
  position: absolute;
  z-index: 1051;
  max-width: 50vw !important;
  width: 50vw !important;
  height: 70%;
  padding-bottom: 2rem;

  .toast-body {
    height: 100%;
  }
}

.refrigerator-sensor-popup {
  position: absolute;
  z-index: 1000;
  max-width: 50vw !important;
  width: 50vw !important;
  height: 63%;
  padding-bottom: 2rem;

  .toast-body {
    height: 100%;
  }
}
.refrigerator-location-popup {
  position: absolute;
  z-index: 1000;
  max-width: 50vw !important;
  width: 50vw !important;
  height: 90%;
  padding-bottom: 2rem;

  .toast-body {
    height: 100%;
  }
}
//로그인 이력 - 검색 조건: 날짜
.datePicker-container {
  input {
    height: 28px;
  }
}

.sensor-status-container {
  & .popup-open {
    position: absolute;
    z-index: 1000;
    background-color: $white;
    max-width: 50vw;
    width: 50vw;
    height: 70%;
    padding-bottom: 2rem;
    overflow: hidden;

    & .toast-body {
      height: 100%;
    }

    @media (max-width: $breakpoint-tablet) {
      width: 90%;
      max-width: 100vw;

      & .toast-body {
        height: 100%;
        overflow: scroll;
      }
    }
  }

  & .sensor-count-card {
    min-width: 100%;

    .card-body {
      min-width: max-content;
    }
  }

  & .sensor-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

// iot 아이템 현황
.status-count-container {
  font-weight: 500;
  font-size: 0.88rem;
  text-align: center;
  word-break: keep-all;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  background-color: $gray-300;
}
.title {
  height: 30px;
  line-height: 30px;
  width: 100%;
  margin: 0;
  //background-color: #094A9A;
  color: white;
  border-radius: 0.5rem 0.5rem 0 0;
  position: relative;
}
.title ul {
  display: flex;
  text-align: center;
  //border-bottom: 1px solid lightgray;
  font-size: 0;
  margin: 0;
  padding: 0;
}
.title ul li {
  color: white;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 20%;
  flex: 0 1 20%;
  white-space: nowrap;
}
.title ul li b {
  font-size: 0.8rem;
  font-weight: bold;
}

.boxCon {
  position: relative;
  //background-color: whitesmoke;
  //padding: 0.7rem;
  font-size: 0.7rem;
  border-radius: 0 0 0.5rem 0.5rem;
  //-webkit-box-shadow: 0 0 3px rgb(0 0 0 / 30%);
  //box-shadow: 0 0 3px 0 rgb(0 0 0 / 30%);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0 / 30%);
}
.boxCon div {
  width: 100%;
}
.boxCon div ul {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: center;
  font-size: 0;
}
.boxCon div ul li {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 20%;
  flex: 0 1 20%;
  white-space: nowrap;
  padding: 0.6rem 0.3rem 0.5rem 0.3rem;
}
.boxCon div ul li b {
  font-size: 0.8rem;
  font-weight: bold;
}
.boxCon div ul li p {
  font-size: 0.8rem;
  padding-top: 0.3rem;
  opacity: 80%;
  margin-bottom: 0;
}
.boxCon::after {
  content: '';
  display: block;
  clear: both;
}
@media (min-width: 880px) and (max-width: 1920px) {
  .vital-signs-monitoring-card {
    width: 50%;
    padding: 0 20px 20px 0;
  }
  .vital-signs-monitoring-card:nth-child(2n) {
    width: 50%;
    padding: 0 0 20px 0;
  }
}
@media (max-width: 851px) {
  .vital-signs-monitoring-card {
    width: 100%;
    padding: 0 0 20px 0;
  }
  .vital-signs-monitoring-card:nth-child(2n) {
    width: 100%;
    padding: 0 0 20px 0;
  }
}

@media (min-width: 1377px) and (max-width: 1920px) {
  .vital-signs-realTime-card {
    width: 50%;
    padding: 0 20px 20px 0;
  }
  .vital-signs-realTime-card:nth-child(2n) {
    width: 50%;
    padding: 0 0 20px 0;
  }
}
@media (max-width: 1376px) {
  .vital-signs-realTime-card {
    width: 100%;
    padding: 0 0 20px 0;
  }
  .vital-signs-realTime-card:nth-child(2n) {
    width: 100%;
    padding: 0 0 20px 0;
  }
}
.custom-modal {
  width: 100%;
}

//chart card css
.chartBoard {
  display: table;
  padding: 20px;
}
.chartBoard .valueBox {
  background-color: #18202c;
  display: table-cell;
  padding: 15px;
  border-radius: 1rem;
  box-shadow: 5px 5px 5px #111111;
}
.chartBoard .valueBox .spo2GoodFontColor {
  color: #00fefe;
}
.chartBoard .valueBox .custom-title {
  font-size: 1.5rem;
}
.chartBoard .valueBox .time {
  font-size: 1.2rem;
  text-align: left;
  color: #ffffff;
}
.chartBoard .valueBox .value {
  font-size: 3rem;
  vertical-align: middle;
}
.chartBoard .valueBox .spo2GoodFontColor {
  color: #00fefe;
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid white;
  border-right: 5px solid white;
  border-top: 5px solid black;
}

.selected-color {
  background-color: #d8e0fe !important;
  color: black;
}

.sensor-report-container {
  display: flex;
  gap: 30px;
  //display: grid;
  //grid-gap: 10px;
  //grid-template-columns: repeat(auto-fit, minmax(350px, auto));
  //grid-template-rows: 200px max-content;

  & .sensor-report-item {
    //justify-self: center;
    align-self: center;

    &:first-child {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media print {
  .print {
    .search-group-print {
      flex-direction: row !important;
    }
  }

  //.widget-header-right{
  //  display: none !important;
  //}
  //
  @page {
    //size: A4;
    //margin: 0;
  }
}

@media (max-width: $breakpoint-mobile) {
  .pnt-input--group {
    width: 100%;
  }
}

.filter-wrap {
  flex-wrap: wrap;
  gap: 5px;
}

// 실시간 위치 현황 -> 검색창 -> dropdown menu -> before, after 지우기
.rbt-menu.dropdown-menu {
  z-index: 1001;
  &:before,
  &:after {
    display: none;
  }
}
.pnt-input--group .rbt button.close {
  display: unset;
}

.pnt-input.disable-rbt-hint + .rbt-input-hint {
  opacity: 0;
}

// 검색 시 검색어와 일치되는 항목 bold 처리
.rbt-highlight-text {
  font-weight: bold !important;
}
