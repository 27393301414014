// Helpers imports
@import 'helpers/opacity';
@import 'helpers/grayscale';

// Font sizes

.font-size-sm {
  font-size: 0.8rem !important;
}

.font-size-md {
  font-size: 0.9rem !important;
}

.font-size-nm {
  font-size: 1rem !important;
}

.font-size-lg {
  font-size: 1.1rem !important;
}

.font-size-xlg {
  font-size: 1.3rem !important;
}

// Layout

.h-100 {
  height: 100vh !important;
}

.he-100 {
  height: 100%;
}

.h-sm {
  height: 150px;
}

.text-nowrap {
  white-space: nowrap;
}

.line-height-1 {
  line-height: 1;
}

// Center element

.center-elem {
  display: flex;
  align-items: center;
  align-content: center;
}

.flex2 {
  flex: 2;
}

// Divider

.divider {
  margin-top: $spacer;
  margin-bottom: $spacer;
  height: 1px;
  overflow: hidden;
  background: $gray-200;
}

.list-group-item:hover {
  z-index: initial;
}

// Background Color Animation

.bg-animation {
  animation: bg-pan-left 8s both;
}

@-webkit-keyframes bg-pan-left {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes bg-pan-left {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
