@import '../variables';
@import '../font';
@import '../theme/default/default';
@import '../tools';

@mixin react-tree-select($form-bg, $form-txt) {
  & .dropdown {
    width: 100%;
    z-index: 401 !important;
  }
  & .dropdown-trigger {
    border-radius: 0.25rem;
    width: 100%;
    height: 28px !important;
    overflow: hidden !important;
    background-color: $form-bg;
    display: flex;
    align-items: center;
  }
  & .dropdown-radio-select {
    height: 28px !important;
  }
  & .dropdown-trigger:hover {
    text-decoration: none !important;
  }
  & .dropdown-trigger > span:after {
    font-size: 12px;
    color: #555;
  }
  & .toggle {
    font-family: 'Material Icons Round';
    font-size: 0.88rem;
    color: #555;
    font-style: normal;
    vertical-align: middle;
  }
  & .toggle.collapsed::after {
    content: 'add_box';
  }
  & .toggle.expanded::after {
    content: 'indeterminate_check_box';
  }
  & .root {
    padding: 0px;
    margin: 0px;
  }
  //.root .node.tree:first-child {
  //    padding-left: 4px !important;
  //}
  & .node > label {
    margin-bottom: 0;
    width: 100%;
  }
  & .dropdown-content {
    min-width: 100%;
    max-height: 300px;
    overflow-y: auto;
    background-color: $form-bg;
  }

  & .tag-item > .tag {
    line-height: 16px;
  }
  & .tag-item > input {
    border: none !important;
    background-color: $form-bg;
    font-weight: 400;
  }

  & .tag-item > input::placeholder {
    color: $form-txt !important;
  }

  & .node > label > input[type='radio'] {
    display: none;
  }
  & .node.disabled > i {
    cursor: pointer;
    color: #555;
  }
  & .node.focused {
    background-color: $gray-400 !important;
    color: red;
  }
  & .search {
    width: 100%;
  }
  & .tag-list .tag-item {
    line-height: 1;
    width: 80%;
    @include p4-n(1);
  }
  & .no-matches {
    display: inline-block;
    margin: 8px 4px;
  }

  & .node > label {
    width: 95%;
    padding: 0.16rem 0;
  }

  & .dropdown-trigger.arrow {
    &.bottom:after,
    &.top:after {
      //content: 'ᐯ' !important;
      color: hsl(0, 0%, 80%) !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }
  }
}

#root .app-container.app-theme-pnt-navy {
  @import '../theme/navy/navy';
  .react-select-item {
    @include react-tree-select($form-bg, $form-txt);
  }
}

#root .app-container.app-theme-pnt-dark {
  @import '../theme/dark/dark';
  .react-select-item {
    @include react-tree-select($form-bg, $form-txt);
  }
}
