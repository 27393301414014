/* font */
*,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
a,
ol,
li,
dl,
dt,
dd,
table,
th,
td,
form,
fieldset,
legend,
input,
textarea,
button,
select {
  font-family: 'Pretendard', sans-serif;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 900;
  font-display: swap;
  src: local('Pretendard Black'), url('../fonts/pretendard/woff2-subset/Pretendard-Black.subset.woff2') format('woff2'),
    url('../fonts/pretendard/woff-subset/Pretendard-Black.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 800;
  font-display: swap;
  src: local('Pretendard ExtraBold'),
    url('../fonts/pretendard/woff2-subset/Pretendard-ExtraBold.subset.woff2') format('woff2'),
    url('../fonts/pretendard/woff-subset/Pretendard-ExtraBold.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-display: swap;
  src: local('Pretendard Bold'), url('../fonts/pretendard/woff2-subset/Pretendard-Bold.subset.woff2') format('woff2'),
    url('../fonts/pretendard/woff-subset/Pretendard-Bold.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  font-display: swap;
  src: local('Pretendard SemiBold'),
    url('../fonts/pretendard/woff2-subset/Pretendard-SemiBold.subset.woff2') format('woff2'),
    url('../fonts/pretendard/woff-subset/Pretendard-SemiBold.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-display: swap;
  src: local('Pretendard Medium'),
    url('../fonts/pretendard/woff2-subset/Pretendard-Medium.subset.woff2') format('woff2'),
    url('../fonts/pretendard/woff-subset/Pretendard-Medium.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-display: swap;
  src: local('Pretendard Regular'),
    url('../fonts/pretendard/woff2-subset/Pretendard-Regular.subset.woff2') format('woff2'),
    url('../fonts/pretendard/woff-subset/Pretendard-Regular.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 300;
  font-display: swap;
  src: local('Pretendard Light'), url('../fonts/pretendard/woff2-subset/Pretendard-Light.subset.woff2') format('woff2'),
    url('../fonts/pretendard/woff-subset/Pretendard-Light.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 200;
  font-display: swap;
  src: local('Pretendard Light'), url('../fonts/pretendard/woff2-subset/Pretendard-Light.subset.woff2') format('woff2'),
    url('../fonts/pretendard/woff-subset/Pretendard-Light.subset.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 100;
  font-display: swap;
  src: local('Pretendard Light'), url('../fonts/pretendard/woff2-subset/Pretendard-Light.subset.woff2') format('woff2'),
    url('../fonts/pretendard/woff-subset/Pretendard-Light.subset.woff') format('woff');
}
