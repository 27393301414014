
@media  only screen and (min-width: 4001px) {
  :root{
    font-size: 32px !important;
  }
  & .metismenu-icon {
    transform : scale(1.5) !important;
  }
}

@media  only screen and (min-width: 2300px) and (max-width: 4000px){
  :root{
    font-size: 30px !important;
  }
  & .metismenu-icon {
    transform : scale(1.5) !important;
  }
}

