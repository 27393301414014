@import '../variables';

#select-container,
#modal-select-container {
  .container_tree_item {
    background-color: $f-w-2;
    color: $gray-700 !important;
    &::-webkit-scrollbar {
      display: none;
    }
    .react-select__option {
      &:hover {
        background-color: magenta !important;
      }
    }
  }

  .react-select__menu .react_map_menu_tree.react-select__menu-list .react-select__option:hover {
    background-color: white !important;
  }
  .react-select__menu .react_menu_tree.react-select__menu-list .react-select__option:hover {
    background-color: $f-b-9 !important;
  }
}

.react_map_tree_Item {
  .react-checkbox-tree {
    .rct-title {
      color: black !important;
    }
  }
}

.react_Tree_Item {
  .react-checkbox-tree {
    padding: 0;
    font-size: 0.8rem;

    .rct-collapse {
      margin: 0;
      display: none;
    }

    .rct-node {
      width: 100%;
      min-height: 28px;

      &:last-of-type {
        & .rct-text {
          padding-bottom: 0;
        }
      }
    }

    span:has(button) {
      flex-direction: row-reverse;
      border-bottom: 1px solid $gray-100;
    }

    .rct-text {
      padding: 0.4rem 0;
      min-height: 28px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      > label {
        width: 100%;
        height: 100%;
        min-height: 28px;
      }
    }

    .rct-node-parent {
      border-bottom: 1px solid $gray-100;

      :last-child {
        border-bottom: 0;
      }
    }

    .rct-node-leaf {
      border-bottom: 1px solid $gray-100;

      .rct-checkbox {
        display: none;
      }
    }

    ol {
      padding-left: 10px;
    }

    ol > li:last-child {
      border-bottom: 0;
    }

    .rct-collapse-btn {
      width: 100%;
      height: 100%;
      justify-content: flex-end;
    }

    .rct-title {
      color: black;
      width: 100%;
      height: 100%;
    }
    .rct-icon {
      display: none;
    }
  }
}
