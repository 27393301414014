@mixin baseChart($depth-5) {
  .highcharts-grid-line {
    stroke: $depth-5;
  }
}

#root .app-container.app-theme-pnt-navy {
  @import '../theme/navy/navy';
  @include baseChart($depth-5);
}

#root .app-container.app-theme-pnt-dark {
  @import '../theme/dark/dark';
  @include baseChart($depth-5);
}
