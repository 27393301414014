.patient-card-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.patient-card {
  border: 1px solid #eee;
  border-radius: 0.25rem;
  font-size: 2rem;
  padding: 0.5rem;
}

.patient-name {
  padding-left: 30px;
}

.abnormal-score {
  display: flex;
  justify-content: center;
}

.monitoring-message {
  padding-left: 30px;
  font-size: 1rem;
}

.risk-level {
  padding: 0 15px;
  border-radius: 50%;
  color: #fff;
  font-weight: 700;

  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;

  &.high {
    background-color: #f63838;
  }
  &.medium {
    background-color: #ffa500;
  }
  &.lowMedium {
    background-color: #fdf927;
    color: #616161;
  }
  &.low {
    background-color: #4ac713;
  }
}
