.react-checkbox-tree {
  font-size: 0.88rem;

  label {
    display: flex;
    align-items: center;
  }

  .rct-text {
    align-items: center;
  }

  .rct-collapse,
  .rct-collapse.rct-collapse-btn {
    display: flex;
    align-items: center;
    padding: 0;
    margin-right: 0.25rem;
  }

  .rct-node-icon,
  .rct-checkbox {
    display: flex;
    padding: 0;
    margin-right: 0.25rem;
  }

  .rct-node-leaf > .rct-text > .rct-collapse > .rct-icon {
    padding: 0;
  }
}
