// TABS

.nav-pills,
.nav-tabs {
  margin-bottom: $spacer;
}

.nav-link {
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}

.nav-tabs {
  .nav-link {
    &:hover {
      color: $primary !important;
    }

    &.active {
      color: $primary;
    }
  }
}

.nav-pills {
  .nav-link {
    &:hover {
      color: $primary !important;
    }

    &.active {
      background: $primary;

      &:hover {
        color: $white !important;
      }
    }
  }
}

// Tabs alternate

.tabs-lg-alternate {
  &.card-header {
    padding: 0;
    height: auto;

    .widget-number {
      font-size: $h2-font-size;
      font-weight: 300;
    }

    .tab-subheading {
      padding: 5px 0 0;
      opacity: 0.6;
      transition: all 0.2s;
    }

    & > .nav {
      margin: 0;

      .nav-link {
        padding: $layout-spacer-x 0;
        border-right: $gray-200 solid 1px;
        background: $gray-100;

        &:hover {
          background: $white;
          .tab-subheading {
            color: $black;
            opacity: 0.9;
          }
        }

        &::before {
          background: $white;
          border-radius: 0;
        }

        &.active {
          background: $white;

          .tab-subheading {
            color: $black;
            opacity: 0.9;
          }
        }
      }

      .nav-item:last-child {
        .nav-link {
          border-right: 0;
        }
      }
    }
  }
}
