// Main Base

.app-main {
  z-index: 8;
  position: relative;
  display: flex;

  .app-main__outer {
    flex: 1;
    flex-direction: column;
    display: flex;
    z-index: 12;
    width: 1px;
  }

  .app-main__inner {
    padding: $grid-gutter-width $grid-gutter-width 0;
    flex: 1;
  }
}

// Themes

@import 'themes/main-white';
@import 'themes/main-gray';
//@import "themes/main-inverted-blue";
//@import "themes/main-inverted-black";
