@import 'font';
@import 'variables';
@import 'tools';

//맵 안의 기능
.leaflet-control {
  //셀렉트박스
  & .react-dropdown-tree-select {
    & .dropdown.radio-select {
      //버튼
      & .dropdown-trigger {
        height: $form-height;
        border: 1px solid $gray-400;
        box-shadow: $form-shadow;
        &::after {
          margin: 0;
          font-size: 0.8rem;
          color: $gray-500 !important;
          position: absolute;
          top: 50%;
          right: 0.5rem;
          transform: translateY(-60%) scale(0.8);
        }
        & .tag-list {
          & .tag-item {
            @include p4-n(1);
            & input {
              border: none;
            }
          }
        }
      }
      //드롭다운
      & .dropdown-content {
        padding: 0.5rem 0 0.5rem 0.5rem;
        & * {
          color: $f-b-3 !important;
        }
        & ul {
          & li {
            border-radius: $form-radius;
            transition: $form-transition;
            &:hover {
              background-color: $gray-200 !important;
            }
          }
        }
      }
    }
  }
  & .react-select {
    box-shadow: $form-shadow;
  }

  //총 아이템 개수, 자세히, 필터 버튼
  &.control-container {
    display: flex;
    & .leaflet-map-btn,
    .leaflet-map-select {
      box-shadow: $form-shadow;
    }
    & .item-container {
      height: $form-height;
      display: flex;
      align-items: center;
      background-color: $white;
      border: 1px solid $gray-400;
      border-radius: 2px;
      gap: 0.3rem;
      padding: 0 0.5rem;
    }
  }

  &.leaflet-control-layers,//레이어
  &.custom-leaflet-control,//중앙정렬
  &.leaflet-control-zoom.leaflet-bar {
    //줌
    //transform: scale(.7) translateX(20%) translateY(-20%);
    border: 1px solid $gray-400 !important;
    box-shadow: $form-shadow !important;
  }
}
